import React, { useState, useEffect } from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import { Popover, Transition, Fragment } from "@headlessui/react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import Logo from "./../images/logoWithAV.svg"

const Footer = ({ footer }) => {
  const handleSubmit = async e => {
    e.preventDefault()
    const result = await addToMailchimp(email)
    console.log(result, email)
    setMessage(result.msg)
    // I recommend setting `result` to React state
    // but you can do whatever you want
  }
  // const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop)
  // const myRef = useRef(null)
  // const executeScroll = () => scrollToRef(myRef)

  useEffect(() => {
    if (footer) {
      setShowForm(true)
    }
  }, [footer])

  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [showText, setShowText] = useState(false)
  const [showForm, setShowForm] = useState(false)
  console.log(showText)
  return (
    <StaticQuery
      query={graphql`
        query {
          strapiGlobal {
            siteName
          }
        }
      `}
      render={data => (
        <footer aria-labelledby="footer-heading">
          <h2 id="footer-heading" className="sr-only">
            Footer
          </h2>
          <div className="mx-auto px-6 py-10 lg:px-32 text-sm lg:hidden">
            <div className="flex">
              {/* Newsletter section */}
              <div className="flex-1 md:mt-0 col-span-3">
                <h3 className="font-bold text-primary">
                  Articulate Ventures LLC{" "}
                </h3>
                <h3 className="font-medium text-primary">
                  St. Louis, MO 63124
                </h3>
                <a
                  className="text-navbar"
                  onClick={() => {
                    navigator.clipboard.writeText("info@articulate.ventures")
                  }}
                >
                  info@articulate.ventures
                </a>
                <div>
                  <a
                    href="https://www.youtube.com/channel/UCigB7W5bX_gCinJxev9WB8w"
                    target="_blank"
                    className="text-navbar"
                  >
                    Twitter
                  </a>
                  /
                  <a
                    href="https://www.linkedin.com/company/articulate-ventures"
                    target="_blank"
                    className="text-navbar"
                  >
                    LinkedIn
                  </a>
                </div>
              </div>

              {/* Newsletter section */}
              <div className="md:mt-0 col-span-3">
                <div>
                  <h3 className="font-bold text-primary">Like what we do?</h3>
                  <Link to="/contact" className="mt-2 text-navbar">
                    Get in touch
                  </Link>
                </div>
              </div>
            </div>

            <div className="mt-6">
              <h3 className="font-bold text-primary">
                Want to hear from us more often?
              </h3>
              <a
                href="#foot"
                className="mt-2 text-navbar"
                onClick={() => setShowForm(!showForm)}
              >
                Subscribe to our Newsletter
              </a>
            </div>

            {/* Image section */}
            <div className="col-span-6 border-t border-black my-6">
              <img src={Logo} alt="" className="h-28 w-auto mt-6" />
            </div>
          </div>

          <div className="mx-6 lg:mx-16 my-16 text-base lg:block hidden">
            <div className="grid grid-cols-1 md:grid-cols-12 md:grid-flow-col md:gap-x-8 md:gap-y-16 md:auto-rows-min items-center">
              {/* Image section */}
              <div className="col-span-6">
                <img src={Logo} alt="" className="h-28 w-auto" />
              </div>
              {/* Newsletter section */}
              <div className="mt-12 md:mt-0 col-span-3">
                <div>
                  <h3 className="font-bold text-primary">Like what we do?</h3>
                  <Link to="/contact" className="mt-2 text-navbar">
                    Get in touch
                  </Link>
                </div>
                <div className="mt-4">
                  <h3 className="font-bold text-primary">
                    Want to hear from us more often?
                  </h3>
                  <a
                    href="#foot"
                    className="mt-2 text-navbar"
                    onClick={() => setShowForm(!showForm)}
                  >
                    Subscribe to our Newsletter
                  </a>
                </div>
              </div>
              {/* Newsletter section */}
              <div className="mt-12 md:mt-0 col-span-3">
                <h3 className="font-bold text-primary">
                  Articulate Ventures LLC{" "}
                </h3>
                <h3 className="font-medium text-primary">
                  St. Louis, MO 63124
                </h3>
                <Popover>
                  <span
                    className="text-navbar pointer"
                    onClick={() => {
                      setShowText(!showText)

                      navigator.clipboard.writeText("info@articulate.ventures")
                    }}
                  >
                    info@articulate.ventures
                  </span>
                  <div className="text-sm">
                    {showText ? "Email copied to clipboard" : ""}
                  </div>
                </Popover>
                <div className="mt-4">
                  <a
                    href="https://www.youtube.com/channel/UCigB7W5bX_gCinJxev9WB8w"
                    target="_blank"
                    className="text-navbar"
                  >
                    YouTube
                  </a>
                  /
                  <a
                    href="https://www.linkedin.com/company/articulate-ventures"
                    target="_blank"
                    className="text-navbar"
                  >
                    LinkedIn
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="subform mx-6 lg:mx-16 my-4 text-base lg:block ">
            {showForm && (
              <>
                <div id="foot">
                  <form
                    className="flex justify-between"
                    onSubmit={e => handleSubmit(e)}
                  >
                    <input
                      type="text"
                      placeholder="Add your email address"
                      onChange={e => {
                        setEmail(e.currentTarget.value)
                      }}
                    />
                    <button>Subscribe</button>
                  </form>
                  {message ? (
                    <p
                      style={{ color: "black", fontSize: "16px" }}
                      dangerouslySetInnerHTML={{ __html: message }}
                    ></p>
                  ) : (
                    ""
                  )}
                </div>

                <hr />
              </>
            )}
          </div>
        </footer>
      )}
    />
  )
}

export default Footer
