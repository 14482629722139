import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";

const Nav = () => (
  <StaticQuery
    query={graphql`
      query {
        strapiGlobal {
          siteName
        }
      }
    `}
    render={(data) => (
      <nav className="shadow-lg">
      <div className="lg:mx-14 px-4 sm:px-6">
          <div className="relative flex items-center justify-between h-16 font-bold text-sm lg:text-semismall text-primary navbar">
            <Link to="/" className="hover:text-navbar hover:text-shadow" activeClassName="text-navbar">Home</Link>
            <Link to="/services" className="hover:text-navbar hover:text-shadow" activeClassName="text-navbar">Services</Link>
            <Link to="/blog" className="hover:text-navbar hover:text-shadow" activeClassName="text-navbar">Prespectives</Link>
            <Link to="/about" className="hover:text-navbar hover:text-shadow" activeClassName="text-navbar">About</Link>
            <Link to="/contact" className="hover:text-navbar hover:text-shadow" activeClassName="text-navbar">Contact</Link>
          </div>
        </div>
      </nav>
    )}
  />
);

export default Nav;